import React from 'react'
import Layout from '../components/layout/Layout'
import { Link, graphql } from 'gatsby'
import { slugify } from '../util/utilityFunctions'
import { DiscussionEmbed } from 'disqus-react'
import SEO from '../components/common/SEO'

const SinglePost = ({ data }) => {
  const post = data.markdownRemark.frontmatter
  const disqusShortname = 'ahmedhadjou'
  const disqusConfig = {
    identifier: data.markdownRemark.id,
    title: post.title,
  }
  // let codeBlocks = document.querySelectorAll('pre code')
  // $('pre code').each(function(i, block) {
  //   hljs.highlightBlock(block);
  // });

  return (
    <Layout pageTitle={post.title}>
      <SEO
        title={post.title}
        description={data.markdownRemark.fields.description}
        pathname={data.markdownRemark.fields.slug}
      />
      <div className="single-blog-post">
        <h1>{post.title}</h1>
        <p className="blog-post-meta">{post.date}</p>
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        <br />
        <ul className="post-tags">
          {post.tags.map(tag => (
            <Link
              to={`/tag/${slugify(tag)}`}
              className="tag text-uppercase"
              key={tag}
            >
              {tag}
            </Link>
          ))}
        </ul>
        <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
      </div>
    </Layout>
  )
}

export const singlePostQuery = graphql`
  query postBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMM Do YYYY")
        tags
      }
      fields {
        slug
        description
      }
    }
  }
`

export default SinglePost
